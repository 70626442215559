<template>
  <div class="tenant-questionnaire">
    <div class="container">
      <div class="page-header">
        <a href="/" class="page-main-link">{{$t('main')}}</a>
        <a href="/tenants" class="page-tenant-link">{{$t('forTenants')}}</a>
        <a class="page-current-link">{{$t('fillForm')}}</a>
      </div>

      <h1 class="title">{{$t('rentForm')}}</h1>
      <span class="subtitle"
        >{{$t('formContent')}}</span
      >

      <tenantForm />
    </div>
  </div>
</template>

<script>
export default {
  name: "TenantQuestionnaire",
  components: {
    TenantForm: () =>
      import("@/components/pages/tenant-questionnaire/TenantForm.vue"),
  },
};
</script>
